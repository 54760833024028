interface IProps {
    videoUrl: string;
    videoTitle: string;
    title: string;
    text: string;
}

const VideoTile : React.FunctionComponent<IProps> = (props) => {
    return (
        <div className='container homepage-content-card-background'>
          <section id="info" className="info-section container py-5" >
            <div className="row align-items-center">
              <div className="col-md-6 mb-3">
                <div className="ratio ratio-16x9">
                  <iframe
                      className="video-iframe"
                      src={props.videoUrl}
                      title={props.videoTitle}
                      allowFullScreen />
                </div>
              </div>
              <div className="col-md-6 text-container">
                <h3>{props.title}</h3>
                <p>{props.text}</p>
              </div>
            </div>
          </section>
        </div>
    )
}

export default VideoTile;