import { child, get } from "@firebase/database";
import { deleteObject, getDownloadURL, uploadBytes } from "@firebase/storage";
import { getDatabaseReference, getStorage } from "../../firebase";
import { urls } from "../../urls";
import { ISortable } from "../../components/itemSort/Sort";

export interface IDocument extends ISortable {
    DocumentUrl: string;
    OriginalName: string;
}

const useDocumentService = () => {
    const dbRef = getDatabaseReference(urls.Document.Index);

    const getDocuments = async() : Promise<Array<IDocument>> => {
        const result = await get(child(dbRef, '/'));
        return result.val();
    };

    const deleteDocument = async (document: IDocument) : Promise<void> => {
        const storage = getStorage(`${urls.Document.Storage}/${document.OriginalName}`);
        await deleteObject(storage);
    }

    const uploadDocument = async (file: File) : Promise<string> => {
        const storage = getStorage(`${urls.Document.Storage}/${file.name}`);
        const uploadResult = await uploadBytes(storage, file);
        const downloadUrl = await getDownloadURL(uploadResult.ref);
        return downloadUrl;
    }

    return { getDocuments, uploadDocument, deleteDocument };
};

export { useDocumentService };