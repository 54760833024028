import React, { PropsWithChildren, ReactNode, useEffect, useState } from "react";

interface IProps {
    triggerFetchProperty?: any;
    dataMethod: () => Promise<any> | any;
    children: (data: any, getDataMethod: () => Promise<void>) => ReactNode;
};

const DataFetcher : React.FunctionComponent<PropsWithChildren<IProps>> = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getData = async () : Promise<void> => {
        try {
            const result = await props.dataMethod();
            if (result) {
                setData(result);
            }
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getData();
    }, [props.triggerFetchProperty]);

    return (
        <>
            {isLoading ? <React.Fragment/> : props.children(data, getData)}
        </>
    );
};

export { DataFetcher };