import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { getAuthorization } from "../firebase";

interface IAuthContextProps {
    isAdminLoggedIn?: boolean;
    isAuthenticating?: boolean;
    login?: () => void;
    logout?: () => void;
}

const AuthContext = React.createContext<IAuthContextProps>({});

const AuthContextProvider : React.FunctionComponent = (props) => {
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const firebaseAuth = getAuthorization();
    const authProvider = new GoogleAuthProvider();

    const isAdminCredentials = (userUid?: string) : boolean => {
        return userUid != null && userUid === process.env.REACT_APP_AUTH_UID;
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (currentUser) => {
            const isAdmin = isAdminCredentials(currentUser?.uid);
            setIsAdminLoggedIn(isAdmin);
            setIsAuthenticating(false);
        });

        return () => unsubscribe();
    }, []);

    const login = async () => {
        try {
            setIsAuthenticating(true);
            await signInWithPopup(firebaseAuth, authProvider);
        } catch (error) {
            setIsAuthenticating(false);
        }
    };

    const logout = async () => {
        try {
            setIsAuthenticating(true);
            await signOut(firebaseAuth).then(_ => setIsAdminLoggedIn(false));
        } finally {
            setIsAuthenticating(false);
        }
    }

    const values : IAuthContextProps = { isAdminLoggedIn, isAuthenticating, login, logout };

    return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
};

export { AuthContext, AuthContextProvider }