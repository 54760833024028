import { FileInput } from "../../../components/FileInput";
import { IDocument, useDocumentService } from "../../../dataServices/document/documentService"
import { getDatabaseReference } from "../../../firebase";
import { urls } from "../../../urls";
import { useFileHelper } from "../../../helpers/fileHelper";
import { set } from "@firebase/database";
import { useState } from "react";

interface IProps {
    Documents: Array<IDocument>;
    getDataMethod: () => Promise<void>;
}

const DocumentUpload : React.FunctionComponent<IProps> = (props) => {
    const [isUploading, setIsUploading] = useState(false);
    const fileHelper = useFileHelper();
    const documentService = useDocumentService();

    const onUpload = async (files: File[]) => {
        setIsUploading(true);
        const promises = files.map(async (file, index) : Promise<IDocument> => ({
                DocumentUrl: await documentService.uploadDocument(file),
                index: props.Documents.length + index,
                Label: fileHelper.getFileLabel(file.name),
                OriginalName: file.name,
            }));
        
        const uploadResult = await Promise.all(promises);
        const newDocumentList = [...props.Documents, ...uploadResult];
        const dbRef = getDatabaseReference(urls.Document.Index);
        await set(dbRef, newDocumentList).then(() => {
            setIsUploading(false);
            props.getDataMethod();
        });
    }

    return (
        <>
        {isUploading 
            ? <div>Nahrávání souborů</div> 
            : (
                <>
                    <h2>Nahrát soubory</h2>
                    <div>
                        <FileInput
                            buttonLabel="Nahrát soubory"
                            onUpload={onUpload}
                            multiple={true} />
                    </div>
                </>
            )}
        </>
    )
}

export { DocumentUpload };