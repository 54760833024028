import { set } from "@firebase/database";
import { ISortable, Sort } from "../../../components/itemSort/Sort";
import { IDocument } from "../../../dataServices/document/documentService";
import { getDatabaseReference } from "../../../firebase";
import { urls } from "../../../urls";

interface IProps {
    Documents: Array<IDocument>;
}

const DocumentsOrder : React.FunctionComponent<IProps> = (props) => {

    const onSave = async (documents: Array<ISortable>) : Promise<void> => {
        const dbRef = getDatabaseReference(urls.Document.Index);
        await set(dbRef, documents).then(() => {
            window.alert('Pořadí úspěšně změněno!');
        });
    }

    return (
        <>
            <h2>Změnit pořadí souborů</h2>
            <Sort Items={props.Documents} onSave={onSave} />
        </>
    )
}

export { DocumentsOrder };