import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';

export interface ISortable {
    index: number;
    Label : string;
}

interface IProps {
    Items: Array<ISortable>;
    onSave: (items: Array<ISortable>) => Promise<void>;
}

const Sort : React.FunctionComponent<IProps> = (props) => {
    const [items, setItems] = useState<Array<ISortable>>(props.Items);

    const handleDragEnd = (result: DropResult) => {
        const { source, destination } = result;
    
        // If no valid destination, exit
        if (!destination) return;
    
        // Reorder the items based on drag result
        const updatedItems = Array.from(items);
        const [movedItem] = updatedItems.splice(source.index, 1);
        updatedItems.splice(destination.index, 0, movedItem);
    
        // Update indexes
        const reorderedItems = updatedItems.map((item, index) => ({
          ...item,
          index: index,
        }));
    
        setItems(reorderedItems);
      };

      useEffect(() => {
        setItems(Array.from(props.Items));
      }, [props.Items]);

    return (
        <>
            {props.Items.length > 0 &&
                <>
                    <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable-list">
                        {(provided: DroppableProvided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((item, index) => (
                            <Draggable key={item.index} draggableId={item.index.toString()} index={index}>
                                {(provided: DraggableProvided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                    padding: 10,
                                    margin: '0 0 10px 0',
                                    backgroundColor: '#f4f4f4',
                                    border: '1px solid #ddd',
                                    ...provided.draggableProps.style,
                                    }}
                                >
                                    {item.Label}
                                </div>
                                )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                        )}
                    </Droppable>
                    </DragDropContext>
                    <button onClick={() => props.onSave(items)}>Uložit pořadí</button>
                </>
            }
            {props.Items.length === 0 && <div>Žádné soubory nenalezeny</div> }
        </>
      );
}

export { Sort };