import { IImageMetadata } from "../../components/Carousel";

const imagePaths = () => {
    const homePagePhotos : Array<IImageMetadata> =
        [
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FHosteradiceFHD.jpg?alt=media&token=7b88a2c3-1a36-4e1a-80b9-f16f66641cf0',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FHosteradice4k.jpg?alt=media&token=b015a8b0-6333-4052-8bfe-61bd3e62687c',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FHosteradiceMobile.jpg?alt=media&token=7364863d-7033-4386-aa1c-3afa8f96fba0',
                altInfo: 'Kostel sv. Kunhuty v Hostěradicích',
                description: 'Kostel sv. Kunhuty v Hostěradicích',
                title: 'Římskokatolická farnost Hostěradice'
            },
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FSkaliceFHD.jpg?alt=media&token=ee89c8e8-5442-40ac-89f0-d41887efba80',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FSkalice4k.jpg?alt=media&token=61828af9-b6b3-4b12-adc1-7a2d43a0e862',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FSkaliceMobile.jpg?alt=media&token=8debc180-fd9a-4282-b69e-8046cb1de003',
                altInfo: 'Kaple sv. Víta ve Skalici',
                description: 'Kaple sv. Víta ve Skalici',
                title: 'Římskokatolická farnost Hostěradice'
            },
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FTrsteniceFHD.jpg?alt=media&token=4d1db450-9e30-41b0-9bf5-5455aa60a95c',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FTrstenice4k.jpg?alt=media&token=a4c7f5e1-0b0d-4088-8106-1a401b84b42a',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FTrsteniceMobile.jpg?alt=media&token=79d07de3-c3a3-499f-9af5-6b69ee121ae5',
                altInfo: 'Kostel Povýšení sv. Kříže v Trstěnicích',
                description: 'Kostel Povýšení sv. Kříže v Trstěnicích',
                title: 'Římskokatolická farnost Trstěnice',
            },
            {
                imagePathFHD: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FMorasiceFHD.jpg?alt=media&token=0af456c6-5c83-466f-ac55-de5d855701d4',
                imagePath4K: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FMorasice4k.jpg?alt=media&token=75771290-370a-4ba1-8c30-7e905a065d7e',
                imagePathMobile: 'https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Ftesthomepage%2FMorasiceMobile.jpg?alt=media&token=efe83248-ac31-47db-bdf4-90a9d559639d',
                altInfo: 'Kostel sv. Anny v Morašicích',
                description: 'Kostel sv. Anny v Morašicích',
                title: 'Římskokatolická farnost Trstěnice'
            },
        ];

    return { homePagePhotos };
};

export { imagePaths };