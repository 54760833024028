import Carousel from '../components/Carousel';
import { imagePaths } from '../content/images/imagePaths';
import Footer from '../components/Footer';
import MassesTile from '../components/tiles/MassesTile';
import VideoTile from '../components/tiles/VideoTile';

const MainPage = () => {
    const { homePagePhotos } = imagePaths();
    
    return (
        <div className="d-flex flex-column min-vh-100 bg-gradient">
          <Carousel images={homePagePhotos} />

          <VideoTile 
            title='Vánoční koncert v Hostěradicích'
            text='V sobotu 4. 1. 2025 v kostele sv. Kunhuty v Hostěradicích se konal vánoční koncert pěveckého sboru Canticum a komorního orchestru při ZUŠ Němčice nad Hanou, při kterém zazněla vánoční mše Jakuba Jana Ryby.
            Výtěžek z koncertu je určen na rekonstrukci našeho kostela.'
            videoTitle='Vánoční koncert PS Canticum'
            videoUrl='https://www.youtube.com/embed/PJ5Pj_sFQEg?si=gkXJWmhRQt46lWQI' />
          
          <VideoTile 
            title='Vánoční mše Ignáce Händela'
            text='V neděli 29. 12. 2024 na svátek Svaté rodiny zpíval při bohoslužbě chrámový sbor vánoční mši Ignáce Händela. Zhlédněte záznam z bohoslužby a vraťte se na chvíli do doby vánočních svátků.'
            videoTitle='Vánoční mše Ignáce Händela'
            videoUrl='https://www.youtube.com/embed/xt_DgHOaB8M?si=_OTMTvHsdzBi2Ax4' />

          <MassesTile />

          <Footer />
      </div>
    );
  };

  export default MainPage;