import { DataFetcher } from "../../dataServices/core/DataFetcher";
import { IMassDay, useMassDayService } from "../../dataServices/massDay/massDayService";
import { MassDay } from "../../pages/Masses/MassDay";

const MassesTile : React.FunctionComponent = () => {
    const massDayDataService = useMassDayService();

    return (
        <div className='container homepage-content-card-background'>
            <h3 style={{margin: "1rem"}}>Pořad bohoslužeb pro tento týden</h3>
            <DataFetcher dataMethod={massDayDataService.getMassDays}>
                {(data: Array<IMassDay>) => (
                    <>
                        {data.map((massDay, index) => (
                            <div className="row" key={index}>
                                <div className="col-lg-3 col-md-3 col-sm-1"/>
                                <div className="col">
                                    <MassDay {...massDay}/>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-1"/>
                            </div>
                        ))}
                    </>
                )}
            </DataFetcher>
        </div>
    )
}

export default MassesTile;