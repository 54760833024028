const urls = {
    Annoucment: {
        Index: 'Annoucment',
    },
    Document: {
        Index: 'Documents',
        Storage: 'Documents',
    },
    Gallery: {
        Storage: 'Gallery',
        FolderInfo: 'GalleryFolders',
        FolderDetail: 'GalleryFolderDetails',
    },
    MassDay: {
        Index: 'MassDay',
    }
}

export {urls};