import { set } from "@firebase/database";
import { IDocument, useDocumentService } from "../../../dataServices/document/documentService";
import { getDatabaseReference } from "../../../firebase";
import { urls } from "../../../urls";

interface IProps {
    Documents: Array<IDocument>;
    getDataMethod: () => Promise<void>;
}

const DocumentDelete: React.FunctionComponent<IProps> = (props) => {
    const documentService = useDocumentService();

    const onDocumentDeleteClick = async (document: IDocument) : Promise<void> => {
        await documentService.deleteDocument(document);
        const documentsWithoutDeleted = props.Documents
            .filter(d => d.OriginalName !== document.OriginalName)
            .map((item, index) => ({
                ...item,
                index: index,
            }));
        const dbReference = getDatabaseReference(urls.Document.Index);
        await set(dbReference, documentsWithoutDeleted).then(() => {
            props.getDataMethod();
        });
    }

    return (
        <>
            <h2>Smazání souborů</h2>
            {props.Documents.length > 0 &&
                props.Documents.map((document, index) => (
                <div key={index} style={{marginBottom: '1rem'}}>
                    <span>{document.OriginalName}</span>
                    <button className="trash-button" onClick={() => onDocumentDeleteClick(document)} title='Smazat soubor'></button>
                </div>
                ))
            }

            {props.Documents.length === 0 && <div>Žádné soubory nenalezeny</div> }
        </>
    )
}

export { DocumentDelete };