interface IProps {
    url: string;
    name: string;
    iconUrl?: string;
    openInNewTab?: boolean;
};

const Link : React.FunctionComponent<IProps> = (props) => {
    return (
        <a 
            className="list-group-item list-group-item-action d-flex align-items-center" 
            href={props.url} 
            target={props.openInNewTab === false ? '' : "_blank"} 
            rel='noreferrer'>
                {props.iconUrl && <img src={props.iconUrl} alt="link icon" style={{width: "auto", height: "20px", marginRight: '8px'}}/>}
                {props.name}
        </a>
    );
};

export default Link;