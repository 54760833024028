import { DataFetcher } from "../../../dataServices/core/DataFetcher";
import { IDocument, useDocumentService } from "../../../dataServices/document/documentService";
import { DocumentUpload } from "./DocumentUpload";
import { DocumentsOrder } from "./DocumentsOrder";
import { DocumentDelete } from "./DocumentDelete";

const DocumentManagement : React.FunctionComponent = () => {
    const documentDataService = useDocumentService();

    return (
        <>
            <DataFetcher dataMethod={documentDataService.getDocuments}>
                {(data: Array<IDocument>, getDataMethod) => (
                    <>
                        <DocumentUpload Documents={data} getDataMethod={getDataMethod} />
                        <DocumentDelete Documents={data} getDataMethod={getDataMethod} />
                        <DocumentsOrder Documents={data} />
                    </>
                    )}
            </DataFetcher>
        </>
    )
};

export { DocumentManagement };