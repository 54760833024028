import Link from "../../components/Link";
import { useLinkService } from "../../dataServices/link/linkService";

const Links : React.FunctionComponent = () => {
    const linkDataService = useLinkService();

    return (
        <>
        <h3>Odkazy</h3>
            <div className="list-group">
                {linkDataService.getLinks().map((link, index) => (
                    <Link key={index} name={link.Name} url={link.Url} iconUrl={link.IconPath} />
                ))}
            </div>
      </>
    )
}

export default Links;