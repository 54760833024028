export interface ILink{
    Name: string;
    Url: string;
    IconPath: string;
};

const links : Array<ILink> = [
    {
        Name: 'Biskupství brněnské',
        Url: 'https://www.biskupstvi.cz/aktualne',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/BiskupstviBrnenske.svg`
    },
    {
        Name: 'Obec Hostěradice',
        Url: 'https://www.hosteradice.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/ObecHosteradice.png`
    },
    {
        Name: 'Apoštolský stolec',
        Url: 'https://www.vatican.va/content/vatican/it.html',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/ApostolskyStolec.png`
    },
    {
        Name: 'Salesiání Brno',
        Url: 'https://brno.sdb.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/SalesianiBrno.png`
    },
    {
        Name: 'Farnost Hluboké Mašůvky',
        Url: 'http://www.farnostmasuvky.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/Masuvky.JPG`
    },
    {
        Name: 'Signály',
        Url: 'https://www.signaly.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/Signaly.svg`
    },
    {
        Name: 'Noc kostelů',
        Url: 'https://www.nockostelu.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/NocKostelu.png`
    },
    {
        Name: 'Katolík',
        Url: 'http://www.katolik.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/Katolik.jpg`
    },
    {
        Name: 'Víra',
        Url: 'https://www.vira.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/Vira.png`
    },
    {
        Name: 'Mapa bohoslužeb',
        Url: 'https://www.ibohosluzby.cz/',
        IconPath: `${process.env.PUBLIC_URL}/icons/links/iBohosluzby.png`
    }
]

const useLinkService = () => {

    const getLinks = () : Array<ILink> => {
        return links;
    };

    return { getLinks };
};

export { useLinkService };