import React from "react";

const useFileHelper = () => {

    const getBlobFiles = (e: React.ChangeEvent<HTMLInputElement>) : File[] => {
        const files = getFileList(e);
        return files ?? [];
    };

    const readFileAsJson = <TModel>(file: File) : Promise<TModel> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const jsonResult = JSON.parse(reader.result as string);
                resolve(jsonResult as TModel);
            };

            reader.onerror = () => {
                reject(new Error('Soubor se nepodarilo precist'));
            };

            reader.readAsText(file);
        })
    };

    const getFileList = (e: React.ChangeEvent<HTMLInputElement>) : File[] | undefined => {
        const files = e.target.files;
        if (!files) {
            e.target.value = '';
            alert('Soubor není ve správném formátu nebo se nenačetl správně. Opakute prosím akci znovu.');
        } else {
            const result = Array.from(files);
            return result;
        }
    };

    const removeFileNameExtension = (fileName: string) : string => {
        return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    };

    const replaceUnderscoresWithWhiteSpace = (fileName: string) : string => {
        return fileName.replace('_', ' ');
    };

    const getFileLabel = (fileName: string) : string => {
        const withoutExtension = removeFileNameExtension(fileName);
        const result = replaceUnderscoresWithWhiteSpace(withoutExtension);
        return result;
    }

    return { getBlobFiles, getFileLabel, readFileAsJson };
};

export { useFileHelper };